<template>
  <div>
    <p>
      <img :src="UseGuideImage01" alt="image01" />
    </p>
    <p>
      <img :src="UseGuideImage02" alt="image02" />
    </p>
    <p>
      <img :src="UseGuideImage03" alt="image03" />
    </p>
  </div>
</template>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>

<script>
import UseGuideImage01 from "@/assets/guide/sample-01.png";
import UseGuideImage02 from "@/assets/guide/sample-02.png";
import UseGuideImage03 from "@/assets/guide/sample-03.png";

export default {
  name: "HowToUse.vue",
  data() {
    return {
      UseGuideImage01,
      UseGuideImage02,
      UseGuideImage03,
    };
  },
};
</script>
